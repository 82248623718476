<template>
  <StoryEdit :story-id="storyId" />
</template>

<script>
import StoryEdit from '@/components/Story/StoryEdit';

export default {
  name: 'StoryInfo',
  components: { StoryEdit },

  props: {
    storyId: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>
